import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import ContactForm from '../components/ContactForm';

import '../css/pages/_contact-page.scss';

export default function contact({ data }) {
  const contactInfo = data.sanitySiteInfo.contact;
  const ph1 = contactInfo.phone.slice(0, 2);
  const ph2 = contactInfo.phone.slice(2, 5);
  const ph3 = contactInfo.phone.slice(5, 8);
  const ph4 = contactInfo.phone.slice(8, contactInfo.phone.length);
  const fullPhone = `${ph1} (${ph2}) ${ph3} - ${ph4}`;

  return (
    <Layout>
      <SEO title="Contact us" />

      <div className="contact-page">
        {/* Hero section */}
        <Hero h1="Contact us" />

        <section className="contact">
          <div className="wrapper">
            <div className="reach-us">
              <div className="contact-group">
                <h6>Email</h6>
                <a href={`mailto:${contactInfo.email}`} className="underline">
                  {contactInfo.email}
                </a>
                <a href={`mailto:${contactInfo.email2}`} className="underline">
                  {contactInfo.email2}
                </a>
              </div>

              <div className="contact-group">
                <h6>Phone</h6>
                <a href={`tel:${contactInfo.phone}`} className="underline">
                  {fullPhone}
                </a>
              </div>

              <div className="contact-group">
                <h6>Address</h6>
                <p>{contactInfo.address}</p>
              </div>
            </div>

            {/* Contact form */}
            <ContactForm />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    sanitySiteInfo {
      contact {
        address
        email
        email2
        phone
      }
    }
  }
`;
